<template>
  <div class="del-toponym">
    <p>{{ actions }}</p>
    <button
        class="btn btn-danger btn-del-toponym"
        @click="deleteOrder"
    >
      Удалить
    </button>
    <button
        class="btn btn-success btn-del-toponym"
        @click="canselDelete"
    >
      Отменить
    </button>
  </div>
</template>

<script>
export default {
  name: 'DeleteModal',
  props: {
    actions: {
      type: String,
      default: ''
    }
  },
  methods: {
    deleteOrder() {
      this.$emit('deleteOrder')
    },
    canselDelete() {
      this.$emit('cancelDel')
    }
  }
}
</script>

<style>
.del-toponym {
  text-align: center;
  padding: 20px 20px 10px 20px;
  font-size: 15px;
}
.btn-del-toponym {
  padding: 3px 8px 3px 8px;
  margin: 10px;
}
</style>