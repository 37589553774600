<template>
  <div id="kt_header" class="header header-fixed">
    <div
      class="container-fluid d-flex align-items-stretch justify-content-between"
    >
      <div
        class="header-menu-wrapper header-menu-wrapper-left"
        id="kt_header_menu_wrapper"
      >
        <div
          id="kt_header_menu"
          class="header-menu header-menu-mobile header-menu-layout-default"
        >
          <!--begin::Header Nav-->
          <ul class="menu-nav">
            <router-link
              class="menu-item menu-item-submenu menu-item-rel"
              v-for="link in links"
              :key="link.url"
              tag="li"
              active-class="menu-item-active"
              :to="link.url"
              exact
            >
              <a href="#" class="menu-link menu-toggle">
                <span class="menu-text">{{ link.title }}</span>
                <i class="menu-arrow"></i>
              </a>
            </router-link>
          </ul>
          <!--end::Header Nav-->
        </div>
      </div>
      <div
        class="header-menu-wrapper header-menu-wrapper-right d-flex align-items-center"
      >
        <button class="btn btn-secondary" @click="logout()">Выйти</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    links: [
      { title: "Топонимы", url: "/" }
    ],
  }),
  methods: {
    logout() {
      axios.post(process.env.VUE_APP_API_URL + "/logout").then(() => {   
        document.location.href = '/login'
      });
    },
  },
};
</script>
