<template>
  <div class="d-flex flex-column flex-root">

    <HeaderMobile />

    <div class="d-flex flex-row flex-column-fluid page">

      <Aside />

      <!--begin::Wrapper-->
      <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">

        <Header />

        <!-- <Preloader /> -->

        <!--begin::Content-->
        <div id="kt_content" class="content d-flex flex-column flex-column-fluid">

          <!--begin::Entry-->
          <div class="d-flex flex-column-fluid">

            <!--begin::Container-->
            <div class="container">
              <!--begin::Dashboard-->

              <router-view />
              <!--end::Dashboard-->
            </div>
            <!--end::Container-->
          </div>
          <!--end::Entry-->
        </div>
        <!--end::Content-->

      </div>
      <!--end::Wrapper-->

    </div>

  </div>
</template>

<script>
import Aside from './aside/Aside'
import HeaderMobile from './header/HiaderMobile'
import Header from "@/views/layout/header/Header"
import Preloader from "@/components/Preloader"
import DelToponym from "@/components/table/DelToponym/DelToponym";

export default {
  name: 'MainLayout',

  components: {
    DelToponym,
    Preloader,
    Header,
    Aside,
    HeaderMobile
  },
}

</script>

<style>
  .authenticated {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #EEF0F8;
    z-index: 1001;
  }
</style>