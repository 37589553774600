<template>
  <DeleteModal
      :actions="getActions.text"
      @deleteOrder="deleteOrder"
      @cancelDel="canselDel"
  />
</template>

<script>
  import {mapGetters} from 'vuex'
  import DeleteModal from "@/components/table/DelToponym/DeleteModal/DeleteModal";

  export default {
    name: 'DelToponym',
    components: {DeleteModal},
    computed: {
      ...mapGetters([
          'getActions',
          'getOrderData'
      ])
    },
    methods: {
      deleteOrder() {    
        this.$store.dispatch('UPDATE_TOPONYM', {
          method: this.getActions.method,
          url: this.getActions.urlApi+'/'+this.getActions.id,
          order: this.getActions.order,
          commitName: this.getActions.commitName,
          id: this.getActions.id,
          idImage: this.getActions.idImage,
          notify: this.$notify,
          modal: this.$modal,
          modalName: 'delete-toponym'
        })
        this.$emit('close')
      },
      canselDel() {
        this.$store.commit('SET_ACTIONS', {})
        this.$emit('close')
      }
    }
  }
</script>

