<template>
  <transition name="fade">
    <div v-if="getIsLoading" class="preloader">
      <div class="is-spinner">
        <Spinner color="#ccc" :size="150"/>
      </div>
    </div>
  </transition>
</template>

<script>
  import {mapGetters} from 'vuex'
  import Spinner from "./Spinner"

  export default {
    computed: {
      ...mapGetters([
        'getIsLoading'
      ])
    },
    components: {Spinner}
  }
</script>

<style>
  .preloader {
    position: fixed;
    display: flex;
    width: 80%;
    height: 80%;
    overflow: hidden;
    /*background: #EEF0F8;*/
    z-index: 1001;
  }
  .is-spinner {
    margin: auto;
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity 2s;
  }
  .preloader {
    animation-name: out;
    animation-duration: 2s;
  }
  @keyframes out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

</style>
